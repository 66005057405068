<template>
  <v-card flat >
      <v-container>
        
        <v-row justify="center">
           <p class="text-h5"> {{$t('Date time settings')}} </p>
        </v-row>
        <v-row justify="center">
           <v-col cols="12" md="4">
          <v-text-field dense readonly outlined :label="$t('Current time (Browser):') + ' ' + lang" v-model="curTime"> </v-text-field>
  
           </v-col>
        </v-row>
        <v-row justify="center">
           <v-col cols="12" md="4">
          <v-text-field dense readonly outlined :label="$t('Current time (Server):') " v-model="serverDate.local_time"> </v-text-field>
           </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="4">
          <v-text-field  dense readonly outlined :label="$t('Servers timezone:') " v-model="serverDate.time_zone"> </v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
           <v-col cols="12" md="4">
          <v-text-field dense readonly outlined :label="$t('System time synchonization:')" v-model="serverDate.time_zone">
            <template v-slot:append>
              <v-icon large :color="serverDate.system_clock_synchronized ? 'green' : 'red'">{{icon}}</v-icon>
            </template>
          </v-text-field>
           </v-col>
        </v-row>
        <v-row justify="center">

          <v-col cols="12" md="4">
            <v-autocomplete
            :items="timezones"
            item-text="label"
            item-value="tzCode"
            :label="$t('Timezone')"
            outlined
            dense
            v-model="timezone"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="4" align="right">
            <v-btn 
            color="#196619"
            :dark="!(timezone==null)"
            large
            class="mt-1"
            @click="setTimezone"
            :loading="loading"
            :disabled="timezone==null"
            >
              {{$t('Set timezone')}}
            </v-btn>
          </v-col>
        </v-row>
        
      </v-container>
  </v-card>
</template>

<script>

import timezones from 'timezones-list';

export default {
    name: "Date",

    data() {
      return {
        curTime: null,
        interval: null,
        timezones,
        timezone: null,
        lang: null,
        serverDate: null,
        loading: false
      }
    },
    methods: {
      async initialize () {
        let res = null
        try { 
          res = await this.$http.get("/datetime")
          this.serverDate = res.data
        } catch (e) {
          console.log(e)
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
        } 
      },
      async setTimezone () {
        try { 
          let res = null
          this.loading = true
          res = await this.$http.post("/datetime/timezone", {time_zone: this.timezone})
          this.serverDate = res.data
          this.timezone = null
          this.$emit("alertMsg", {
            type: "success",
            text: "Data saved OK",
          });
        } catch (e) {
         console.log(e);
        this.$emit("alertMsg", {
          type: "error",
          text: e.message 
        });
        this.loading = false;
        } finally {
          this.loading = false;
        }
      }
      

    },
    computed: {
      icon () {
        return this.serverDate.system_clock_synchronized ? 'mdi-check' : 'mdi-cancel'
      }
    },
    created () {
      this.lang = navigator.language
      this.initialize()
      this.interval = setInterval(() => {
        this.curTime = new Intl.DateTimeFormat(navigator.language, { dateStyle: 'full', timeStyle: 'long'}).format(new Date());
      }, 1000)
    },
    destroy () {
      clearInterval(this.interval)
    }

}
</script>

